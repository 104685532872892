<template>
    <div class="main-pdding">
        <div class="main-box">
            <div class="title">
                <div class="t-label">
                    <label @click="router.push('/productMent/list')">产品列表</label>
                </div>
                <img src="@/assets/images/icon/arrow-rigth-black.png" />
                <div class="t-label current">
                    <label>{{type == 1?'添加产品':'修改产品'}}</label>
                </div>
            </div>
            <el-form :hide-required-asterisk="true" ref="addFormRef" :model="submitData" label-width="123px" :rules="rules">
                <div class="add-box">
                    <div class="select-sort">
                        <label class="add-label">选择分类</label>
                        <div class="sort-form">
                            <el-form-item label="产品分类" prop="category_id">
                                <el-select v-model="submitData.category_id" class="edit-input" placeholder="请选择产品分类">
                                    <el-option v-for="item in proClass" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="add-box">
                    <div class="select-sort">
                        <label class="add-label">产品设置</label>
                        <div class="sort-form">
                            <el-form-item label="产品名称" prop="productName">
                                <el-input v-model="submitData.productName" class="edit-input" placeholder="请输入产品名称"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="关键词">
                                <el-input class="edit-input" placeholder="商品关键词能准确搜到商品的，如：比比牛/写字板之类"></el-input>
                            </el-form-item> -->
                            <el-form-item label="产品轮播图(多张)">
                                <addImg @upimgSuccess="upimgSuccess" :imgUrl="submitData.picUrl"></addImg>
                            </el-form-item>
                            <el-form-item label="上架状态">
                                <el-switch v-model="submitData.status" :active-value="1" :inactive-value="0"></el-switch>
                                <span style="margin-left: 10px">{{ submitData.status == 1 ? "上架中" : "下架中" }}</span>
                            </el-form-item>
                            <el-form-item label="表单ID" prop="form_id">
                                <el-input v-model="submitData.form_id" class="edit-input" placeholder="请输入表单ID"></el-input>
                            </el-form-item>
                            <!-- <el-form-item label="参数标题">
                                <el-input v-model="submitData.paramTitle" class="edit-input"></el-input>
                            </el-form-item> -->
                            <el-form-item label="参数内容">
                                <div class="parameter">
                                    <div class="parameter-box">
                                        <div class="d-select" v-for="(group, i) in submitData.paramContent" :key="i">
                                            <div class="param-name">
                                                <span style="user-select: none" class="name-span">参数名:</span>
                                                <el-input
                                                    placeholder="请输入参数名"
                                                    type="text"
                                                    v-model="group.key"
                                                    class="edit-input"
                                                    style="width: 70%"
                                                ></el-input>
                                                <div @click="deleteItem(i)" style="margin-left: auto; line-height: 1">
                                                    <img class="del-img-icon" src="@/assets/images/icon/trash-can-icon.png" />
                                                </div>
                                            </div>
                                            <div class="param-name">
                                                <span style="user-select: none" class="name-span">参数值:</span>
                                                <el-input
                                                    placeholder="请输入参数值"
                                                    type="text"
                                                    v-model="group.value"
                                                    class="edit-input"
                                                    style="width: 70%"
                                                ></el-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="parameter-btn" v-if="isAddAttrGroups">
                                        <el-button class="btn" @click="popupInfo">选择参数模版</el-button>
                                        <el-button class="btn" @click="paramAdd">添加参数项目</el-button>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </el-form>
            <div class="bottom-btn">
                <div>
                    <el-button class="preview-btn" @click="$router.push('/productMent/list')">取消</el-button>
                    <el-button v-if="type == 1" type="primary" @click="addInfo">添加</el-button>
                    <el-button v-else-if="type == 2" type="primary" @click="editInfo">编辑</el-button>
                </div>
            </div>
        </div>
        <paramPop :dialogVal="paramDiglog" @dialogChange="parampopChange"></paramPop>
    </div>
</template>

<script>
import addImg from "@/components/addIimage/addImg.vue";
import paramPop from "./component/paramPop.vue";
import { sessionGetKey } from "@/utils/sessionStorage.js";
import rules from "@/utils/rules";
export default {
    components: {
        paramPop,
        addImg,
    },
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        this.getClass();
        if(this.$route.query.type){
            this.type = this.$route.query.type
        }
        if(this.$route.query.id){
            this.getGood(this.$route.query.id)
        }
        
    },
    data() {
        return {
            type:'1',
            platform_id: "",
            submitData: {
                id:'',
                productName: "",
                form_id: "",
                category_id: "",
                status: 1,
                picUrl: "",
                paramTitle:'',
                paramContent: [],
            },
            paramDiglog: false,
            proClass: [],
            rules: rules,
        };
    },
    methods: {
        // 获取产品分类
        getClass() {
            const that = this;
            that.$request({
                url: that.$api.goodClass.getClass,
                method: "post",
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
                this.proClass = res.data;
            });
        },
        // 获取单个产品信息
        getGood(id) {
            const that = this
            that.submitData.id = id
            that.$request({
                url: that.$api.good.getGood,
                method: "post",
                data: {
                    id:id
                },
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
                console.log(res,'res123456');
                const good_text = JSON.parse(res.data.data[0].good_text)
                that.submitData.category_id = res.data.data[0].category_id
                that.submitData.productName = res.data.data[0].good_name
                that.submitData.picUrl = good_text.pic
                that.submitData.status = res.data.data[0].is_status
                that.submitData.form_id = res.data.data[0].form_id
                that.submitData.paramContent = good_text.attr
            });
        },
        // 参数内容添加
        paramAdd() {
            try {
                if (this.submitData.paramContent.length > 10) {
                    throw new Error("数量最多不超过10");
                }
                this.submitData.paramContent = this.submitData.paramContent.concat({
                    key: "",
                    value: "",
                });
            } catch (err) {
                this.$alert(err.message);
            }
        },
        // 参数内容删除
        deleteItem(i) {
            this.submitData.paramContent.splice(i, 1);
        },
        // 点击按钮选择弹框
        popupInfo() {
            this.paramDiglog = true;
        },
        // 参数弹框事件
        parampopChange(type,data) {
            const that = this;
            this.paramDiglog = false;
            if(type == 1){
                that.submitData.paramTitle = data.name
                for(let item of JSON.parse(data.content))
                that.submitData.paramContent.push(item)
            }
        },
        //图片上传
        upimgSuccess(imgUrl) {
            this.submitData.picUrl = imgUrl;
        },
        // 添加
        addInfo() {
            const that = this;
            let data = {};
            let { productName, form_id, category_id, status, picUrl, paramContent } = this.submitData;

            try {
                if (paramContent && paramContent.length) {
                    let s = paramContent.some((item) => {
                        return item.key == "" || item.value == "";
                    });
                    if (s) {
                        throw new Error("参数名或参数值不能为空");
                    }
                }

                if (picUrl == "") {
                    throw new Error("产品图片不能为空");
                }
            } catch (err) {
                that.$alert(err.message);
                return;
            }

            data = {
                name: productName,
                form_id,
                status,
                good_text: {
                    pic: picUrl,
                    attr: paramContent,
                },
                category_id,
            };

            that.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.good.addGood,
                        method: "post",
                        data: data,
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/productMent/list");
                    });
                }
            });
        },
        //修改
        editInfo(){
          const that = this;
            let data = {};
            let {id,productName, form_id, category_id, status, picUrl, paramContent } = this.submitData;

            try {
                if (paramContent && paramContent.length) {
                    let s = paramContent.some((item) => {
                        return item.key == "" || item.value == "";
                    });
                    if (s) {
                        throw new Error("参数名或参数值不能为空");
                    }
                }

                if (picUrl == "") {
                    throw new Error("产品图片不能为空");
                }
            } catch (err) {
                that.$alert(err.message);
                return;
            }

            data = {
                id,
                good_name: productName,
                form_id,
                is_status:status,
                good_text: {
                    pic: picUrl,
                    attr: paramContent,
                },
                category_id,
            };

            that.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.good.editGood,
                        method: "post",
                        data: data,
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/productMent/list");
                    });
                }
            });
        }
    },
    computed: {
        isAddAttrGroups() {
            return this.submitData.paramContent.length < 5;
        },
    },
};
</script>

<style lang="scss" scoped>
.edit-input {
    border-radius: 6px;
    border: 1px solid #999;
    /deep/.el-input__inner {
        border: 0 !important;
    }
}

.title {
    .t-label {
        display: inline-block;
        height: 35px;

        label {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            font-weight: 600;
        }
    }

    img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    .current {
        border-bottom: 2px solid #4458fe;
        label {
            color: #4458fe;
        }
    }
}

.add-box {
    margin-top: 20px;
}

.select-sort {
    border: 1px solid #edefff;
    border-radius: 6px;

    .add-label {
        display: block;
        height: 46px;
        line-height: 46px;
        padding-left: 20px;
        background-color: #edefff;
    }

    .sort-form {
        padding: 24px 152px;

        .tips {
            color: #4458fe;
        }
    }
}

.good-quill {
    border: 1px solid #edefff;
    border-radius: 6px;
    .add-label {
        display: block;
        height: 46px;
        line-height: 46px;
        padding-left: 20px;
        background-color: #edefff;
    }
    .quill {
        padding: 25px;
        .editor {
            /deep/.ql-editor {
                min-height: 200px !important;
            }
        }
    }
}

.bottom-btn {
    margin-top: 25px;
    text-align: center;
    .preview-btn {
        border: 1px solid #999999;
        color: #999999;
        margin-right: 20px;
    }
}

.color-grey {
    color: #999999;
}

//参数内容
.parameter {
    padding: 12px;
    border: 1px solid #999999;
    border-radius: 6px;

    .parameter-box {
        .d-select {
            background-color: #f5f6fa;
            padding: 10px;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            .param-name {
                display: flex;
                margin-bottom: 10px;
                height: 44px;
                .name-span {
                    width: 60px;
                    color: #999999;
                }
                .del-img-icon {
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .parameter-btn {
        padding: 12px;
        background-color: #f5f6fa;
        margin-top: 10px;
        .btn {
            border: 1px solid #999999;
            border-radius: 6px;
            background-color: #ffffff;
        }
    }
}
</style>
