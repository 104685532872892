/* 过滤树形数组，包含children的选项均禁用 */
function filterTreeData(treeData) {
	return treeData.filter(item => {
		if (isNotEmpty(item.children)) {
			item.disabled = true;
			item.children = filterTreeData(item.children)
		}
		return item
	})
}
function isNotEmpty(arr) {
  return arr && Array.isArray(arr) && arr.length > 0;
}
export default {
	filterTreeData
}
