<template>
    <div>
        <el-dialog :visible.sync="dialogVal" width="20%" center :show-close="false">
            <span slot="title" class="title">
                <span>选择参数模版</span>
                <img class="close-icon" src="@/assets/images/icon/close-black.png" @click="cancel" />
            </span>
            <div class="content">
                <el-tree
                    class="filter-tree"
                    :data="treeData"
                    :props="defaultProps"
                    @check="checkChange"
                    node-key="id"
                    accordion
                    check-strictly
                    highlight-current
                    show-checkbox
                    :filter-node-method="filterNode"
                    ref="tree"
                >
                </el-tree>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
import ArrayUtils from "@/utils/arrayTree.js";
export default {
    props: {
        dialogVal: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            platform_id: "",

            filterText: "", //检索字段
            defaultProps: {
                children: "children",
                label: "name",
            }, //过滤使用字段
            treeData: [], //资产大类树形结构数据
            checkedData: {}, //当前点击的数据
        };
    },
    watch: {
        /** 监听检索字段变化 */
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        this.getTemplate();
        ArrayUtils.filterTreeData(this.treeData);
    },
    methods: {
        confirm() {
            if(JSON.stringify(this.checkedData) === '{}' ){
                this.$message.error('请选择一个模版再确定')
                return
            }
            this.$emit("dialogChange", 1,this.checkedData);
        },
        cancel() {
            this.$emit("dialogChange", 2);
        },
        getTemplate() {
            const that = this;
            that.$request({
                url: that.$api.goodTemplate.getTemp,
                method: "post",
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
                this.treeData = res.data;
            });
        },
        /* 控制树形单选 */
        checkChange(data, checked) {
            if (checked) {
                if (!!data.children && data.children.length > 0) {
                    console.log("有子节点不可选");
                } else {
                    this.checkedData = data;
                    this.$refs.tree.setCheckedKeys([data.id]);
                }
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    .close-icon {
        float: right;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.content {
    color: #333333;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

::v-deep .el-dialog {
    border-radius: 16px;
}

::v-deep .el-dialog__wrapper {
    display: flex;
    align-items: center;
}

.title {
    .title-txt {
        float: left;
        font-size: 20px;
        font-weight: 600;
    }
    .close-icon {
        float: right;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.content {
    max-height: 500px;
    overflow-y: auto;
}

::v-deep .el-dialog {
    border-radius: 16px;
}

::v-deep .el-dialog__wrapper {
    display: flex;
    align-items: center;
}
</style>
